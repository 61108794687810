const domains = {
  // Example: {
  //     name: 'example (same in Paths/appPaths)',
  //     aliasImport: '@platform/example',
  //     path: 'PATH IN URL',
  //     auth: boolean if true won't allow the use without authentication
  //     isService: boolean if true this is a JS service to be reused in other applications
  //     hideLayout: boolean if true HIDE all MFES with isLayout true
  //     isLayout: boolean if true when a MFE marked as hideLayout is true this MFE is hided
  // },
  sidebarSuite: {
    name: 'zsuite-sidebar',
    aliasImport: '@zsuite/sidebar',
    path: '',
    auth: true,
    isLayout: true,
    isSidebar: true,
  },
  navbarSuite: {
    name: 'zsuite-navbar',
    aliasImport: '@zsuite/navbar',
    path: '',
    auth: true,
    isLayout: true,
    isNavbar: true,
  },
  reportsSuiteConsumption: {
    name: 'zsuite-reports',
    aliasImport: '@zsuite/reports',
    path: '/analysis/consumption',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  homeSuite: {
    name: 'zsuite-home',
    aliasImport: '@zsuite/home',
    path: '/welcome',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  onboardingSuite: {
    name: 'zsuite-onboarding',
    aliasImport: '@zsuite/onboarding',
    path: '/onboarding',
    unloadApp: true,
    auth: true,
    hideLayout: true,
    isLayout: false,
  },
  marketplaceSuite: {
    name: 'zsuite-marketplace',
    aliasImport: '@zsuite/marketplace',
    path: '/marketplace',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  SelfService: {
    name: 'selfservice',
    aliasImport: '@platform/selfservice',
    path: '/settings',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  SelfServiceFacebook: {
    name: 'selfservice-facebook',
    aliasImport: '@platform/selfservice',
    path: '/home/credentials/facebook/list',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
    exact: true,
  },
  SelfServiceInstagram: {
    name: 'selfservice-instagram',
    aliasImport: '@platform/selfservice',
    path: '/home/credentials/instagram/list',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
    exact: true,
  },
  CustomerPanel: {
    name: 'customer-area',
    aliasImport: '@platform/customer-area',
    path: '/customer-area',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  Templates: {
    name: 'templates',
    aliasImport: '@platform/templates',
    path: '/templates',
    unloadApp: true,
    auth: true,
    hideLayout: true,
    isLayout: false,
  },
  PlatformReportContactsReport: {
    name: 'platform-report-contacts-report',
    aliasImport: '@platform/platform-report',
    path: '/analysis/contacts-report',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
}

const nluLiteDomains = {
  nluCommonsList: {
    name: 'nluCommonsList',
    aliasImport: '@platform/nlu-commons',
    path: '/chatbot/list',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  nluCommonsNew: {
    name: 'nluCommonsNew',
    aliasImport: '@platform/nlu-commons',
    path: '/chatbot/new',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  nluCommonsDocuments: {
    name: 'nluCommonsDocuments',
    aliasImport: '@platform/nlu-commons',
    path: '/chatbot/document',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  nluCommonsContacts: {
    name: 'nluCommonsContacts',
    aliasImport: '@platform/nlu-commons',
    path: '/chatbot/contacts',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  nluLiteFlowMap: {
    name: 'nluLiteFlowMap',
    aliasImport: '@platform/nlu-lite',
    path: '/chatbot/flow-map',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  nluLiteNewFlowChatbot: {
    name: 'nluLiteNewFlowChatbot',
    aliasImport: '@platform/nlu-lite',
    path: '/chatbot/flow-chatbot/new',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
    exact: true,
  },
  nluLiteBuilder: {
    name: 'nluLiteBuilder',
    aliasImport: '@platform/nlu-lite',
    path: '/chatbot/flow-chatbot/builder',
    unloadApp: true,
    auth: true,
    hideLayout: true,
    isLayout: false,
    isService: false,
  },
  nluLiteSettings: {
    name: 'nluLiteSettings',
    aliasImport: '@platform/nlu-lite',
    path: '/chatbot/flow-chatbot/settings',
    unloadApp: true,
    auth: true,
    hideLayout: true,
    isLayout: false,
    isService: false,
  },
  nluLiteChannels: {
    name: 'nluLiteChannels',
    aliasImport: '@platform/nlu-lite',
    path: '/chatbot/flow-chatbot/channels',
    unloadApp: true,
    auth: true,
    hideLayout: true,
    isLayout: false,
    isService: false,
  },
  nluLiteQualification: {
    name: 'nluLiteQualification',
    aliasImport: '@platform/nlu-lite',
    path: '/chatbot/qualification-via-chatbot',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  masterbotsGenerativeAi: {
    name: 'masterbotsGenerativeAi',
    aliasImport: '@platform/masterbots',
    path: '/chatbot/generative-ai',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  analysisNlu: {
    name: 'zcc-analysis',
    aliasImport: '@platform/nlu-lite',
    path: '/analysis/chatbot',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
    isService: false,
    exact: true,
  },
}

const attractionDomains = {
  CampaignDispatches: {
    name: 'campaign-dispatches',
    aliasImport: '@platform/campaign-dispatches',
    path: '/broadcast',
    unloadApp: true,
    auth: true,
    isLayout: false,
  },
  PlatformBroadcast: {
    name: 'broadcast',
    aliasImport: '@platform/broadcast',
    path: '/broadcast/homepage',
    unloadApp: true,
    auth: true,
    isLayout: false,
  },
  PlatformBroadcastSMS: {
    name: 'broadcast-sms',
    aliasImport: '@platform/broadcast',
    path: '/broadcast/sms',
    unloadApp: true,
    auth: true,
    isLayout: false,
  },
  Automation: {
    name: 'campaign-automation',
    aliasImport: '@platform/campaign-automation',
    path: '/analytics/automations',
    auth: true,
    isLayout: false,
  },
  AutomationBuilder: {
    name: 'builder',
    aliasImport: '@platform/builder',
    path: '/automation/builder',
    auth: true,
    isLayout: false,
    hideLayout: 'sidebar',
  },
  Contacts: {
    name: 'contacts',
    aliasImport: '@platform/contacts',
    path: '/contacts',
    auth: true,
    isLayout: false,
    unloadApp: true,
  },
  BroadcastSocial: {
    name: 'broadcast-social',
    aliasImport: '@platform/broadcast-social',
    path: '/analysis/social',
    auth: true,
    unloadApp: true,
    isLayout: false,
  },
  BroadcastMonitoring: {
    name: 'broadcast-monitoring',
    aliasImport: '@platform/broadcast-monitoring',
    path: '/analytics/broadcast',
    auth: true,
    unloadApp: true,
    isLayout: false,
  },
}

const conversionDomains = {
  ConversionInbox: {
    name: 'conversion-inbox',
    aliasImport: '@platform/conversion-inbox',
    path: '/customer-service/inbox',
    auth: true,
    unloadApp: true,
  },
  ConversionInboxSidebar: {
    name: 'conversion-inbox-sidebar',
    aliasImport: '@platform/conversion-inbox-sidebar',
    path: '/customer-service/inbox',
    auth: true,
    unloadApp: true,
  },
  ConversionSettings: {
    name: 'conversion-settings',
    aliasImport: '@platform/conversion-settings',
    path: '/customer-service/settings',
    auth: true,
    unloadApp: true,
  },
  ConversionActivities: {
    name: 'conversion-activities',
    aliasImport: '@platform/conversion-activities',
    path: '/customer-service/activities',
    auth: true,
    unloadApp: true,
  },
  ConversionIntegrations: {
    name: 'conversion-integrations',
    aliasImport: '@platform/conversion-integrations',
    path: '/customer-service/integrations',
    auth: true,
    unloadApp: true,
  },
  ConversionReports: {
    name: 'conversion-reports',
    aliasImport: '@platform/conversion-reports',
    path: '/analytics/campaigns',
    auth: true,
    unloadApp: true,
  },
  ConversionOverview: {
    name: 'conversion-reports-overview',
    aliasImport: '@platform/conversion-reports',
    path: '/customer-service/overview',
    auth: true,
    unloadApp: true,
  },
  ConversionReportsSales: {
    name: 'conversion-reports-sales',
    aliasImport: '@platform/conversion-reports',
    path: '/analytics/sales',
    auth: true,
    unloadApp: true,
  },
  ConversionSalesContacts: {
    name: 'conversion-sales-contacts',
    aliasImport: '@platform/conversion-reports',
    path: '/sales_contacts',
    auth: true,
    unloadApp: true,
    exact: true,
  },
  ConversionReportsTeams: {
    name: 'conversion-reports-teams',
    aliasImport: '@platform/conversion-reports',
    path: '/analytics/teams-performance',
    auth: true,
    unloadApp: true,
  },
}

const serviceAppDomains = {
  ServiceApp: {
    name: 'service-app',
    aliasImport: '@platform/service-app',
    path: '/service/tickets',
    unloadApp: true,
    auth: true,
  },
  ChatApp: {
    name: 'chat-app',
    aliasImport: '@platform/chat-app',
    path: '/service/chat',
    unloadApp: true,
    auth: true,
  },
  ServiceReports: {
    name: 'service-reports',
    aliasImport: '@platform/service-app',
    path: '/analysis/service',
    unloadApp: true,
    auth: true,
  },
}

const nutrirDomains = {
  NutrirAutomationList: {
    name: 'nutrir-automation-list',
    aliasImport: '@platform/nutrir-automation-list',
    path: '/tools/automations/list',
    exactPath: true,
    auth: true,
    unloadApp: true,
  },
  NutrirAutomation: {
    name: 'nutrir-automation',
    aliasImport: '@platform/nutrir-automation',
    path: '/tools/automations/manage',
    auth: true,
    unloadApp: true,
  },
  NutrirSuccessMonitor: {
    name: 'nutrir-success-monitor',
    aliasImport: '@platform/nutrir-success-monitor',
    path: '/analysis/success-monitor',
    auth: true,
    unloadApp: true,
  },
}

export default {
  ...domains,
  ...attractionDomains,
  ...conversionDomains,
  ...nluLiteDomains,
  ...serviceAppDomains,
  ...nutrirDomains
}
